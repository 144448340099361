<template>
    <section class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <div class="content">
                    <nav class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <h1 class="title">
                                    {{ $t('general.history') }}
                                </h1>
                            </div>
                        </div>

                        <div class="level-right">
                            <div class="level-item">
                                <b-field>
                                    <b-input
                                        v-model="search"
                                        type="search"
                                        icon="magnify"
                                        has-icon-left
                                        class="searchField is-pulled-right"
                                        :placeholder="
                                            $t(
                                                'history.searchField.placeholder'
                                            )
                                        "
                                    />
                                </b-field>
                            </div>
                        </div>
                    </nav>
                    <b-field grouped group-multiline>
                        <div
                            v-for="(data, index) in years"
                            :key="data.year"
                            class="control"
                        >
                            <b-tag type="is-info" class="is-light">
                                <a @click="getSpecificYear(data.year)">
                                    {{ data.year }} ({{ data.bookings }}
                                    {{ $t('history.amount') }})
                                </a>
                            </b-tag>
                            <br v-if="index > 2" />
                        </div>
                    </b-field>
                    <br />
                    <hr />
                </div>
                <div v-if="!hasHistory" class="content">
                    <p class="has-text-centered">
                        {{ $t('history.noDeliveredOrders') }}
                    </p>
                </div>
                <div class="content">
                    <b-loading
                        :is-full-page="isFullPage"
                        :active.sync="isLoading"
                    ></b-loading>
                    <b-collapse
                        v-for="(bookings, name, index) of organized"
                        :key="index"
                        class=""
                        :open="isOpen == index"
                        @open="isOpen = index"
                    >
                        <div
                            slot="trigger"
                            slot-scope="props"
                            class="card-header has-background-light"
                            role="button"
                        >
                            <p class="card-header-title">
                                <span>
                                    {{ name }}
                                    <span class="subtitle is-7">
                                        ({{ bookings.length }}
                                        {{ $t('history.amount') }})
                                    </span>
                                </span>
                            </p>
                            <a class="card-header-icon">
                                <b-icon
                                    :icon="props.open ? 'menu-down' : 'menu-up'"
                                />
                            </a>
                        </div>
                        <div class="card-content">
                            <div
                                v-for="b in bookings"
                                :key="b.uuid"
                                class="columns is-1 history"
                            >
                                <div class="column is-paddingless is-2">
                                    {{
                                        b.confirmedDate | formatDate('do, EEEE')
                                    }}
                                </div>
                                <div class="column is-paddingless  is-3">
                                    {{ b.locationRoute }}
                                    {{ b.locationStreetNumber }}
                                </div>
                                <div class="column is-paddingless ">
                                    {{ b.productName }}
                                </div>
                                <div class="column is-paddingless ">
                                    {{ b.customer.fullName }}
                                </div>
                                <div
                                    class="column is-paddingless  is-1 has-text-right"
                                >
                                    <a href="#" target="_blank">
                                        <b-icon icon="image" size="is-small" />
                                    </a>
                                    <a href="#" target="_blank">
                                        <b-icon icon="eye" size="is-small" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import format from '@/_lib/format'

export default {
    name: 'History',
    data() {
        return {
            isOpen: 0,
            search: '',
            isFullPage: false,
            reverseMonths: {
                january: 0,
                february: 1,
                march: 2,
                april: 3,
                may: 4,
                june: 5,
                july: 6,
                august: 7,
                september: 8,
                october: 9,
                november: 10,
                december: 11
            }
        }
    },
    computed: {
        isLoading() {
            return this.$store.getters['history/isLoading']
        },
        years() {
            return this.$store.getters['history/years']
        },
        history() {
            return this.$store.getters['history/history']
        },
        hasHistory() {
            return this.$store.getters['history/hasHistory']
        },
        filteredList() {
            if (!('bookings' in this.history)) {
                return []
            }
            return this.history.bookings.filter(object => {
                return (
                    object.fullAddress
                        .toLowerCase()
                        .includes(this.search.toLowerCase()) ||
                    object.customer.fullName
                        .toLowerCase()
                        .includes(this.search.toLowerCase()) ||
                    object.productName
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                )
            })
        },
        organized() {
            return this.filteredList.reduce((base, booking) => {
                let month = format(booking.confirmedDate, 'MMMM yyyy')

                base[month] = base[month]
                    ? base[month].concat(booking)
                    : [booking]
                return base
            }, {})
        }
    },
    methods: {
        getSpecificYear(year) {
            this.$store.dispatch('history/getSpecificYear', year)
        }
    },
    created() {
        this.$store.dispatch('history/getYears')
        this.getSpecificYear(new Date().getFullYear())

        this.isOpen = this.$route.query.month
            ? this.reverseMonths[this.$route.query.month.toLowerCase()]
            : 0
    }
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}

.history:nth-child(even) {
    background: #f5f5f5;
}
.history:nth-child(odd) {
    background: #fff;
}
</style>
